const state = () => ({
    show_snackbar: false,
    snackbar_text: '',
    snackbar_type: '',
})

const mutations = {
    showSnackbar(state, item): void {
        state.snackbar_text = item.text
        state.snackbar_type = item.type
        state.show_snackbar = true
    },
    hideSnackbar(state): void {
        state.show_snackbar = false
    },
}

const actions = {
    async showSnackbar({ commit }, payload): Promise<void> {
        await commit('showSnackbar', { text: payload.snackbar_text, type: payload.snackbar_type })
    },
    async hideSnackbar({ commit }): Promise<void> {
        await commit('hideSnackbar')
    },
}

const getters = {
    show_snackbar: (state) => state.show_snackbar,
    snackbar_text: (state) => state.snackbar_text,
    snackbar_type: (state) => state.snackbar_type,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
