<template>
    <div id="snackbar-component">
        <v-snackbar v-model="show_snackbar" :color="snackbar_type" :data-cy="'snackbar-' + snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action>
                <v-icon class="color-white-fix" @click="show_snackbar = false">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'SnackbarComponent',
    computed: {
        show_snackbar: {
            get: function () {
                return this.$store.getters['snackbar/show_snackbar']
            },
            set: function (value) {
                this.$store.dispatch('snackbar/hideSnackbar')
            },
        },
        snackbar_type: function () {
            return this.$store.getters['snackbar/snackbar_type']
        },
        snackbar_text: function () {
            return this.$store.getters['snackbar/snackbar_text']
        },
    },
}
</script>
