<template>
    <div id="pwa-sens-banner-module-alert-component">
        <v-expand-transition>
            <div v-if="banner_configuration && banner_configuration.message">
                <v-alert
                    :color="`#${banner_configuration.background_color}`"
                    class="font-14 mb-0"
                    close-icon="mdi-close"
                    density="comfortable"
                    dismissible
                    text
                >
                    <span
                        :style="`color: #${banner_configuration.background_color} !important`"
                        v-html="formatBannerMessage(banner_configuration.message)"
                    />
                </v-alert>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import ProfessionalRepository from '@/repositories/ProfessionalRepository'

export default {
    name: 'PwaSensBannerModuleAlertComponent',
    methods: {
        fetchProfessionalModulesConfiguration() {
            if (this.userHasAccess('PWA_SENS_BANNER', this.role_vision)) {
                ProfessionalRepository.getProfessionalModulesConfiguration(
                    this.$auth.user().id_professional,
                    this.$auth.user().role_permissions.find((module) => module.designation_module === 'PWA_SENS_BANNER').id_module
                )
                    .then((success) => {
                        this.banner_configuration = success.data.data
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            }
        },
        formatBannerMessage(text) {
            const urlRegex = /((https?:\/\/[^\s]+))/g
            return (
                text
                    //Replace links with <a> tags
                    .replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
            )
        },
    },
    data() {
        return {
            banner_configuration: {},
        }
    },
    created() {
        this.$auth.load().then(async () => {
            this.fetchProfessionalModulesConfiguration()
        })
    },
}
</script>
