import ProfessionalRepository from '@/repositories/ProfessionalRepository'
import { IModule } from '@/models/IModule'
import { IAssetModule } from '@/models/IAssetModule'
import AssetRepository from '@/repositories/AssetRepository'
import { IAssetCompleteInformation } from '@/models/IAssetCompleteInformation'
import { IAssetLiquid } from '@/models/IAssetLiquid'
import AssetLiquidRepository from '@/repositories/AssetLiquidRepository'

const state = () => ({
    asset_modules: [],
    assets: [],
    liquids: []
})

const mutations = {
    setAssetModules(state, asset_modules: IAssetModule[]): void {
        state.asset_modules = asset_modules
    },

    setAssetsByAssetModule(state, payload: { id_module: number; assets: IAssetCompleteInformation[] }): void {
        state.assets[payload.id_module] = payload.assets
    },

    setLiquidsByAssetModule(state, payload: { id_module: number; liquids: IAssetLiquid[] }): void {
        state.liquids[payload.id_module] = payload.liquids
    },

    cleanAssetModuleStore(state): void {
        state.asset_modules = []
        state.assets = []
        state.liquids = []
    }
}

const actions = {
    cleanAssetModuleStore({ commit }) {
        commit('cleanAssetModuleStore')
    },

    async fetchAssetModules({ commit }): Promise<void> {
        return ProfessionalRepository.getProfessionalModulesV2().then((success) => {
            const modules: Array<IModule> = success.data.data.filter((module: IModule) => module.is_generic)
            modules.forEach((module, index) => {
                AssetRepository.getAssetsCountByIdModuleAndIdClient(module.id_module).then((success) => {
                    if (success.data.data.count === 0) {
                        modules.splice(modules.findIndex(item => item === module), 1)
                    }
                })
            })

            commit('setAssetModules', modules)
        })
    },

    async fetchAssetsOfAssetModule({ commit }, id_module: number): Promise<void> {
        return AssetRepository.getAssetsByIdModuleAndIdClient(id_module).then((success) => {
            commit('setAssetsByAssetModule', { id_module: id_module, assets: success.data.data.data })
        })
    },

    async fetchAssetLiquidByIdModule({ commit }, payload: { id_pro: number; id_module: number }): Promise<void> {
        return AssetLiquidRepository.getAssetLiquidByIdModule(payload.id_pro, payload.id_module).then((success) => {
            commit('setLiquidsByAssetModule', { id_module: payload.id_module, liquids: success.data.data })
        })
    }
}

const getters = {
    asset_modules: (state) => state.asset_modules,

    assets_by_asset_module: (state) => (id_module) => {
        return state.assets[id_module]
    },

    assets_by_asset_module_and_id_asset: (state) => (id_module, id_asset) => {
        return state.assets[id_module].find((asset) => asset.id_asset === parseInt(id_asset))
    },

    asset_model_by_id: (state) => (id_module: number, id_asset_model: number) => {
        return state.asset_modules
            .find((asset_module) => asset_module.id_module === id_module, 1)
            .asset_models.find((asset_model) => asset_model.id_asset_model === id_asset_model, 1)!
    },

    liquid_by_id_module_and_id_asset_and_model: (state) => (id_module: number, id_asset: number, index_model: number) => {
        const configuration = JSON.parse(state.assets[id_module].find((asset) => asset.id_asset === id_asset, 1).configuration)

        return state.liquids[id_module].find((liquid) => liquid.id_asset_liquid === configuration[index_model].id_asset_liquid, 1)!
    },

    liquids_by_asset_module: (state) => (id_module: number) => {
        return state.liquids[id_module]
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
